import React from 'react'
import { Link } from 'gatsby'
import { ArrowCircleRightIcon, ArrowCircleLeftIcon, XIcon, LogoutIcon } from '@heroicons/react/outline'
import Layout from '../components/layout'


export default function Portfolio_Details_template({ location, pageContext }) {


    return (
        <Layout location={location} >
            <div>
                <div className=" relative bg-light-blue p-4" >
                    <div className="flex justify-end">
                        <Link to="/portfolio/">  <XIcon className="h-10 w-10" /> </Link>
                    </div>
                    <div className="m-4 p-4">
                        <div className="flex justify-center relative">
                            <Link to={pageContext.prev}>      <ArrowCircleLeftIcon className="absolute top-60 left-48 h-12 w-12" aria-hidden="false" /> </Link>
                            <div className=" bg-orange-primary rounded-3xl text-center max-w-5xl  md:rounded-3xl relative flex flex-col justify-center items-center p-16">
                                <img src={pageContext.portfolio.logo_thumbnail} alt="Profile pic" className="h-40 w-60 rounded-xl " />
                                <div className=" text-white text-xl mt-10 ">{pageContext.portfolio.long_description}</div>
                                <a href={pageContext.portfolio.website_link} target="_blank"><div className="text-base font-semibold text-indigo-600 hover:text-indigo-500 mt-10">Visit website</div> </a>
                            </div>
                            <Link to={pageContext.next}>     <ArrowCircleRightIcon className="absolute top-60 right-48 h-12 w-12" aria-hidden="false" /> </Link>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>

    )
}